/* -------------------------------------------------------------------------- */
/*                                    Hover                                   */
/* -------------------------------------------------------------------------- */

.hover-actions {
  display: none;
  position: absolute;
  &.show {
    display: flex;

    & + .hover-hide {
      display: none;
    }
  }
}
.hover-actions-trigger {
  position: relative;

  // Style for email Inbox
  .inbox-link {
    color: inherit;
    text-decoration: none;
  }
  @include hover-focus {
    .hover-actions {
      z-index: 1;
      display: flex;
    }
    .hover-hide {
      display: none;
    }
  }
}

