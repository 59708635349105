/*-----------------------------------------------
|   Navigation Top
-----------------------------------------------*/
.navbar-top {
  position: sticky;
  top: 0;
  font-size: map_get($font-sizes, "-1");
  font-weight: $font-weight-semi-bold;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  z-index: 1020;
  background-image: none;
  .navbar-nav-icons {
    .dropdown-menu {
      position: absolute;
    }
  }
  .navbar-collapse {
    overflow: auto;
    max-height: calc(100vh - #{$top-nav-height});
    margin: 0 -0.75rem;
    padding: 0 0.75rem;
    order: 1;
  }
  .navbar-toggler {
    margin-left: -0.625rem;
  }
}
.dropdown-menu-card {
  padding: 0;
  .card {
    border: 0;
  }
}
.navbar-toggler {
  border: 0;
  padding: 0;
}

// Settings Icon
.settings-popover {
  position: relative;
  padding: 0;
  height: 1.875rem;
  width: 1.875rem;
  outline: none;
}