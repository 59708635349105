

/*-----------------------------------------------
|   Safari clipping fix
-----------------------------------------------*/
.overflow-hidden[class*='rounded']{
  mask-image: radial-gradient($white, $black);
}

.border-2x{ border-width: 2px !important; }
