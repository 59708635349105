/* -------------------------------------------------------------------------- */
/*                                  Accordion                                 */
/* -------------------------------------------------------------------------- */

.accordion-icon{ 
  transition: transform 0.1s linear;
  color: $gray-900;
  
  [aria-expanded="true"] & {
    transform: rotate(90deg); 
  }
}
